html {
    overflow: scroll;
    overflow-x: hidden;
}
::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: transparent;
}

body{
    background-color: white;
}

.main{
    margin-bottom:-200px;
}
.feed-title{
    font-style:normal;
    font-size: x-large;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: -10px;
}
.video-title{
    margin-left: 10px;
    font-style:normal;
    font-size: xx-large;
    font-family: Arial, Helvetica, sans-serif;
}

.footer{
    background-color: lightgray;
    margin-top: 500px;
}
.footer-links{
    padding-top: 1px;
}
